export const saving_summary_fields = [
    {
        key: 'id',
        label: '#',
        sortable: true
    },
    {
        key: 'name',
        sortable: true,
    },
    {
        key: 'phone_number',
        sortable: true,
    },
    {
        key: 'account_number',
        sortable: true,
    },
    {
        key: 'chama_total_savings',
        label: "Saved Amount",
        sortable: true
    },
    {
        key: 'total_savings',
        sortable: true
    },
    {
        key: 'available_amount',
        sortable: true
    },
    {
        key: 'total_loans_borrowed',
        sortable: true,
    },

    {
        key: 'total_loans_paid',
        sortable: true,
    },
    {
        key: 'interest_earned',
        sortable: true,
    },
    {
        key: 'days_to_maturity',
        sortable: true,
    },
    {
        key: 'savings_plan',
        sortable: true,
    },
    {
        key: 'date_joined',
        label: 'Date Joined',
        sortable: true,
    }
];

